<template>
  <div id="header" class="header">
    <div class="header__container container-custom">
      <div class="header-menu">
        <nuxt-link id="logo" class="logo" :to="PAGE_URLS.HOME">
          <BaseImg :src="LOGO_SITE_IMAGE" alt="logo" />
        </nuxt-link>
        <client-only>
          <div class="menu">
            <nuxt-link
              v-for="(item, index) in menuList"
              :id="`link-${item?.name}`"
              :key="index"
              class="menu__item"
              :class="[
                item?.name,
                {
                  openSubMenu: openSubMenu === item?.name,
                  active:
                    ((item?.name === route?.name &&
                      !(route?.name === 'game-type' && route.params.type === 'quay-so')) ||
                      route?.fullPath?.startsWith(item?.activeUrl) ||
                      route?.fullPath?.startsWith(item?.url)) &&
                    item?.name !== 'line'
                }
              ]"
              :to="item?.url"
              @mouseover="onMouseOver(item)"
              @mouseleave="onMouseLeave()"
              @click="onHideMenu()"
            >
              <span class="menu-title">{{ item.title }}</span>
              <span v-if="item.subMenu && item.subMenu.length" class="icon-menu-down" />
              <div v-if="item.subMenu && item.subMenu.length" class="sub-menu" :class="item?.name">
                <div class="sub-menu__container" :class="{ gridmenu: item.subMenu.length > 6 }">
                  <div
                    v-for="(subItem, subIndex) in item.subMenu"
                    :id="`header-${subItem?.name}`"
                    :key="`${index}${subIndex}`"
                    class="sub-menu__item"
                    :class="[subItem?.type, subItem.alias, { active: isActive(subItem) }]"
                    @click.prevent="onClickSubMenu(item, subItem)"
                  >
                    <div class="sub-menu__item--content">
                      <BaseImg
                        class="icon-active"
                        :src="subItem?.icon?.inactive ?? subItem.icon"
                        :alt="subItem.display_name"
                      />
                      <div class="text">
                        <p>{{ subItem.display_name }}</p>
                        <div v-if="(subItem.jackpot || 0) > 0" class="jackpot">
                          <AnimateNumberRolling class="value" :number="subItem.jackpot" />
                        </div>
                      </div>
                    </div>
                    <div v-if="!subItem?.isNotShowLine" class="line" />
                  </div>
                </div>
              </div>
            </nuxt-link>
          </div>
        </client-only>
      </div>
      <div class="header-button">
        <div id="notification-header" class="header-button__notif">
          <BaseImg
            id="bell"
            class="header-button__notif--bell"
            src="/assets/images/components/desktop/icon-notif.svg"
            alt="bell"
          />
        </div>
        <client-only>
          <UserNotLogin v-if="!currentUser" />
          <UserLogged v-else />
        </client-only>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { LOGO_SITE_IMAGE } from '~~/constants/menu'
import BaseImg from '~/components/common/base-img.vue'
import { useAppStore } from '@/store/app'
import { getMenus } from '~/constants/menu'
import UserNotLogin from '~/components/desktop/header/user-not-login.vue'
import UserLogged from '~/components/desktop/header/user-logged.vue'
import AnimateNumber from '~/components/common/animated-number.vue'
import AnimateNumberRolling from '~/components/common/animate-number-rolling.vue'
import { IMenuHeader, ISubMenuHeader } from '~/types/menu.type'
import { useJackpot } from '~/composables/game/useJackpot'
import { useGameStore } from '~~/store/game'
import { PAGE_URLS } from '~/config/page-url'
import { useGame } from '~/composables/game/useGame'
import { useCasino } from '~/composables/casino/useCasino'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot, currentUser } = storeToRefs(store)
const storeGame = useGameStore($pinia)
const jackpotNumber: Ref<number | null> = ref(null)
const { onSumJackpotInterval, onSumJackpotIntervalClear } = useJackpot()
const { providerGames, providerCasino } = storeToRefs(storeGame)
const { MENUS } = getMenus()
const openSubMenu = ref('')
const { fetchCongGame } = useGame()
const { fetchGameCasino } = useCasino()
const route = useRoute()
const router = useRouter()
const onMouseOver = (item: IMenuHeader) => {
  if (item.subMenu && item.subMenu.length) {
    if (item?.name != null) {
      openSubMenu.value = item.name
    }
  }
}
const onMouseLeave = () => {
  openSubMenu.value = ''
}

const fetchData = () => {
  if (providerGames.value?.length === 0) {
    fetchCongGame()
  }
  if (providerCasino.value?.length === 0) {
    fetchGameCasino()
  }
}
const onClickMenu = (item: IMenuHeader) => {
  openSubMenu.value = ''
  router.push(item.activeUrl)
}

const isActive = (subItem: ISubMenuHeader) => {
  return (
    subItem.alias === route.params.type ||
    (`${PAGE_URLS.CASINO}/${subItem?.alias?.toLowerCase()}` === '/livecasino/all' && route.path === '/livecasino') || subItem.url === route.fullPath
  )
}
const onHideMenu = () => {
  openSubMenu.value = ''
}
function onClickSubMenu(item: IMenuHeader, subItem: ISubMenuHeader) {
  openSubMenu.value = ''
  const mainUrl = item.activeUrl.split('/')[1]
  const subItemUrl = subItem?.alias

  if (subItemUrl?.includes(PAGE_URLS.PROMOTION)) {
    return router.push(subItemUrl)
  }

  if (subItemUrl === 'all') {
    return router.push(`/${mainUrl}`)
  }

  if (item.tab === 'tab-promotion') {
    return router.push(`/${mainUrl}`)
  }

  const link = `/${mainUrl}/${subItemUrl}`
  router.push(link)
}
onMounted(() => {
  fetchData()
  onSumJackpotInterval()
  jackpotNumber.value = sumJackpot.value?.jackpotNohu ?? null
})
onBeforeUnmount(() => {
  onSumJackpotIntervalClear()
})

const menuList = reactive(MENUS)
const getSumJackpot = (aliasMenu: string) => {
  switch (aliasMenu) {
    case 'no-hu': {
      return sumJackpot.value.jackpotNohu
    }
    case 'ban-ca': {
      return sumJackpot.value.jackpotFishing
    }
    case 'table-game': {
      return sumJackpot.value.jackpotIngame
    }
    default: {
      return 0
    }
  }
}

watchEffect(() => {
  const updateSubMenu = (submenu: ISubMenuHeader[], type: string) => {
    const targetItem = menuList.find((item) => item.type === type)
    if (targetItem && submenu?.length) {
      targetItem.subMenu = submenu.map((item) => {
        return {
          ...item,
          type,
          jackpot: getSumJackpot(item.alias)
        }
      })
    }
  }

  const subMenusToUpdate = [
    { submenu: providerGames.value, type: 'games' },
    { submenu: providerCasino.value, type: 'livecasino' }
  ]
  subMenusToUpdate.forEach(({ submenu, type }) => updateSubMenu(submenu, type))
})
</script>

<style scoped lang="scss" src="assets/scss/components/desktop/header/index.scss"></style>
